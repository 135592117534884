import React from 'react';
import { useTranslation } from 'react-i18next';

import KxpLogo from '../img/KXP_logo_White.svg';

function Footer() {
    const { t } = useTranslation('index-footer-section');

    return (
        <footer >
            <article className='w-full p-5'>
                <div className=''>
                    <div className='flex  mb-12'>
                        <img className='h-14 md:h-20 xl:h-20' src={KxpLogo} alt='KXP Logo' />
                    </div>

                    <div className='flex flex-wrap gap-12'>
                        <div className='flex flex-col space-y-3 w-full sm:w-1/2 md:w-1/4'>
                            <p className='text-white text-lg font-medium'>{t('offer01.title')}</p>
                            <a className='text-md text-white hover:underline' target='_blank' rel='noopener noreferrer'>Linkedin</a>
                            <a className='text-md text-white hover:underline' target='_blank' rel='noopener noreferrer'>Instagram</a>
                            <a className='text-md text-white hover:underline' target='_blank' rel='noopener noreferrer'>Youtube</a>
                        </div>
                        <div className='flex flex-col space-y-3 w-full sm:w-1/2 md:w-1/4'>
                            <p className='text-white text-lg font-medium'>{t('offer02.title')}</p>
                            <a className='text-md text-white hover:underline' href='/#about_us'>{t('offer02.text01')}</a>
                            <a className='text-md text-white hover:underline' href='/#contact_us'>{t('offer02.text02')}</a>
                            <a className='text-md text-white hover:underline' href='/blog'>{t('offer02.text03')}</a>
                        </div>
                        <div className='flex flex-col space-y-3 w-full sm:w-1/2 md:w-1/4'>
                            <p className='text-white text-lg font-medium'>{t('text01')}</p>
                            <div className='mt-5 flex items-center gap-2'>
                                <p className='text-white'>+55 11 2626-7474</p>
                            </div>
                            <div className='mt-2 flex items-center gap-2'>
                                <p className='text-white'>Av. Nações Unidas, 12901, São Paulo - SP 04578-910</p>
                            </div>
                        </div>

                        <div className='flex flex-col space-y-3 w-full sm:w-1/2 md:w-1/4 mt-12'>
                            <p className='text-white text-lg font-medium'>{t('text02')}</p>
                            <p className='text-white'>+55 11 2626-7474</p>
                            <p className='text-white'>relacionamento@kxp.consulting</p>
                        </div>
                        <div className='flex flex-col space-y-3 w-full sm:w-1/2 md:w-1/4 mt-12'>
                            <p className='text-white text-lg font-medium'>{t('text03')}</p>
                            <p className='text-white'>+55 11 2626-7474</p>
                            <p className='text-white'>support@kxp.consulting</p>
                        </div>
                    </div>
                </div>
            </article>
        </footer>
    );
}

export default Footer;
